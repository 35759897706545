const { env } = require('../environment')
const { mapMaterialToApiGroup } = require('../src/utils/Mapping')
const fetch = require('node-fetch')
const FormData = require('form-data')

const fetchProductsByIdData = options => {
  let url = env.getServiceHost() + 'products/productsByIds?version=6'

  let params = ''

  let form = new FormData()

  let i = 0
  options.forEach(data => {
    const material = mapMaterialToApiGroup(data.material)

    params += '&data[' + i + '][type]=' + material
    form.append('data[' + i + '][type]', material)

    params += '&data[' + i + '][motiveId]=' + data.motiveId
    form.append('data[' + i + '][motiveId]', data.motiveId)

    if (typeof data.ratio !== undefined && data.ratio !== undefined) {
      params += '&data[' + i + '][ratio]=' + data.ratio
      form.append('data[' + i + '][ratio]', data.ratio)
    }

    i += 1
  })

  if (i > 5) {
    return fetch(url, { method: 'post', body: form }).then(response =>
      response.json(),
    )
  } else {
    return fetch(url + params).then(response => response.json())
  }
}

module.exports = { fetchProductsByIdData }
