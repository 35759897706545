import { fetchMaterials } from '../../services/materials_api'
import { useState, useEffect } from 'react'
import {env} from '../../environment'
import {
  localStorage_getItem,
  localStorage_setItem,
} from '../utils/LocalStorageHelper'
import { ProductMaterial } from '../types'
import webPCheck from 'supports-webp'

export const useMaterialsData = () => {
  const [materials, setMaterials] = useState<ProductMaterial[]>([])

  const localeStorageId: string =
    process.env.STORAGE_PREFIX +
    '_materials_' +
    env.getLanguage() +
    '_' +
    process.env.STORAGE_SUFFIX

  useEffect(() => {
    const materialsFromLocalStorage = localStorage_getItem(localeStorageId)

    if (typeof materialsFromLocalStorage == 'string') {
      setMaterials(JSON.parse(materialsFromLocalStorage))
    } else {
      webPCheck.then((webpSupport: boolean) => {
        fetchMaterials(webpSupport).then((response) => {
          setMaterials(Object.values(response.data))
          localStorage_setItem(localeStorageId, JSON.stringify(response.data))
        })
      })
    }
  }, [env.getLanguage()])

  return materials
}
