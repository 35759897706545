const { env } = require('../environment')
const fetch = require('node-fetch')

const fetchMaterials = use_webp => {
  const webpString = use_webp ? '1' : '0'
  const url =
    env.getServiceHost() +
    'products/materials?language=' +
    env.getLanguage() +
    '&version=5&webp=' +
    webpString

  return fetch(url).then(response => response.json())
}

module.exports = { fetchMaterials }
