import { fetchSale } from './sale_api'
import { useState, useEffect } from 'react'
import { daysIntoYear } from '../../utils/utils'
import { GlobalSale } from '../../types'
import { setVoucherCode } from '../cart_hook'
import { localStorage_setItem } from '../../utils/LocalStorageHelper'

// const getLocalStorageId = () => {
//   return (
//     process.env.STORAGE_PREFIX +
//     '_sale_' +
//     getCmsLanguage() +
//     '_day' +
//     daysIntoYear()
//   )
// }
export const useSale = () => {
  return undefined

  // const [sale, setSale] = useState<GlobalSale | undefined>(undefined)

  // useEffect(() => {
  //   let dataFromLocalStorage = localStorage.getItem(getLocalStorageId())
  //   if (typeof dataFromLocalStorage == 'string') {
  //     let result = JSON.parse(dataFromLocalStorage)

  //     if (
  //       typeof result.voucherCode == 'string' &&
  //       result.voucherCode != '' &&
  //       result.voucherCode != '-'
  //     ) {
  //       setSale(result)
  //     }
  //   } else {
  //     fetchSale().then(response => {
  //       if (
  //         typeof response.data.voucherCode == 'string' &&
  //         response.data.voucherCode != '' &&
  //         response.data.voucherCode != '-'
  //       ) {
  //         setVoucherCode(response.data.voucherCode)
  //         setSale(response.data)
  //       }
  //       localStorage_setItem(getLocalStorageId(), JSON.stringify(response.data))
  //     })
  //   }
  // }, [getLocalStorageId()])
  // return sale
}
